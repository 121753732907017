<template>
    <v-dialog 
	v-model="exportDialog" 
	max-width="500"
	persistent>
		<v-card >
			<v-card-title>
				{{lviews.exportTimesheets}}
			</v-card-title>

			<v-divider></v-divider>

			<v-card-text>
				<!-- Select workers to export  -->
				<v-radio-group v-model="options.workersToExport">
					<v-radio
						:label="lviews.allVisible + ` (${allWorkersNumber})`"
						value="all"
					></v-radio>
					<v-radio
						:label="lviews.selectedOnly + ` (${checkedWorkersNumber})`"
						value="checked"
						:disabled="checkedWorkersNumber==0"
					></v-radio>
				</v-radio-group>


				<!-- Select template  -->
				<div class="text-subtitle-1  black--text font-weight-bold ">{{lviews.chooseTemplate}}</div>

				<v-radio-group v-model="options.exportTemplate" class="mt-1">
					<v-radio
						:label="lviews.weekly"
						value="weekly"
					></v-radio>
					<div v-if="options.exportTemplate=='weekly'" style="width: 200px; margin-left: 30px;">
						<v-dialog
							v-model="datePicker.visible"
							width="290px"
							persistent
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									append-icon="mdi-calendar"
									readonly
									:value="displayWeek()"
									:label="lviews.selectWeek"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>

							<v-card>
								<v-date-picker
									v-model="datePicker.newDate"	
								></v-date-picker>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
									text
										@click="() => {datePicker.visible = false; datePicker.newDate=datePicker.date}"
									>
										{{lbuttons.cancel}}
									</v-btn>
									<v-btn
										color="primary"
										text
										@click="() => { datePicker.visible = false; datePicker.date=datePicker.newDate}"
									>
										{{lbuttons.save}}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>	
					</div>

					<v-radio
						:label="lviews.monthly"
						value="monthly"
					></v-radio>
					<div v-if="options.exportTemplate=='monthly'" style="width: 200px; margin-left: 30px;">
						<v-dialog
							v-model="datePicker.visible"
							width="290px"
							persistent
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									:value="displayMonth()"
									append-icon="mdi-calendar"
									readonly
									:label="lviews.selectMonth"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>

							<v-card>
								<v-date-picker
									v-model="datePicker.newMonth"
									type="month"	
								></v-date-picker>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										text
										@click="() => {datePicker.visible = false; datePicker.newMonth=datePicker.month}"
									>
										{{lbuttons.cancel}}
									</v-btn>
									<v-btn
										color="primary"
										text
										@click="() => { datePicker.visible = false; datePicker.month=datePicker.newMonth}"
									>
										{{lbuttons.save}}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>	
					</div>

                    <v-radio
						:label="lviews.selectRange"
						value="range"
					></v-radio>
				</v-radio-group>

				<v-row v-if="options.exportTemplate=='range'" style="width: 200px; margin-left: 30px;">
					<v-dialog
						v-model="datePicker.visible"
						width="290px"
						persistent
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="dateRangeText"
								:label="lviews.selectRange"
								append-icon="mdi-calendar"
								v-bind="attrs"
								v-on="on"
								readonly
							></v-text-field>
						</template>
						
						<v-card>
							<v-date-picker
								v-model="datePicker.newRange"
								range	
							></v-date-picker>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									text
									@click="() => {datePicker.visible = false; datePicker.newRange=datePicker.range}"
								>
									{{lbuttons.cancel}}
								</v-btn>
								<v-btn
									color="primary"
									text
									@click="() => { datePicker.visible = false; datePicker.range=datePicker.newRange}"
								>
									{{lbuttons.save}}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-row>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="grey"
					class="mr-0 mt-0"
					text
					@click="closeDialog"
				>{{lbuttons.cancel}}</v-btn>
				<v-btn
					color="primary"
					class="mr-0 mt-0"
					text
					@click="exportData()"
				>{{lbuttons.export}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
// import axios from 'axios'
// import appConfig from '@/app_config'

export default {

	name: 'ExportTimesheetsDialog',

	data: function(){
		return {
			exportDialog: false,
			options: {
				workersToExport: 'all',
				exportTemplate: 'monthly',
			},
			datePicker:{
				visible: false,
				date: new Date().toISOString().substr(0, 10),
				newDate: new Date().toISOString().substr(0, 10),
				month: new Date(this.date).toISOString().substr(0, 7),
				newMonth: new Date(this.date).toISOString().substr(0, 7),
                range: [new Date(this.date).toISOString().substr(0, 10)],
                newRange: [new Date(this.date).toISOString().substr(0, 10)],
			}
		}
	},
	props: {
		date: String,
		checkedWorkersNumber: Number,
		allWorkersNumber: Number,
    },
	computed: {
		lviews: { get: function () { return this.$t('views.commons.exportDialog'); } },
		lbuttons: { get: function () { return this.$t('commons.buttons'); } },
		lmessages: { get: function () { return this.$t('commons.messages'); } },
		dateRangeText () {
			console
			if(this.datePicker.range.length==2){
				let first= this.datePicker.range[0]<this.datePicker.range[1]? this.datePicker.range[0]: this.datePicker.range[1]
				let last =this.datePicker.range[0]<this.datePicker.range[1]? this.datePicker.range[1]: this.datePicker.range[0]
				return this.dateString(new Date(first))+" - "+ this.dateString(new Date(last))
			}
			return this.dateString(new Date(this.datePicker.range[0]))
		},
	},
    mounted(){
		// console.log(this.datePicker.range)
    },
	methods: {
		openDialog(){
			this.options.workersToExport= 'all',
			this.options.exportTemplate = 'monthly',
			this.datePicker.visible = false,
			this.datePicker.date =new Date(this.date).toISOString().substr(0, 10),	
			this.datePicker.newDate =new Date(this.date).toISOString().substr(0, 10),	
			this.datePicker.month = new Date(this.date).toISOString().substr(0, 7),
			this.datePicker.newMonth = new Date(this.date).toISOString().substr(0, 7),
            this.datePicker.range = [new Date(this.date).toISOString().substr(0, 10)],
            this.datePicker.newRange = [new Date(this.date).toISOString().substr(0, 10)],
			this.exportDialog=true
		},
		closeDialog(){
			this.exportDialog=false
		},

		exportData(){
			let dateFrom
			let dateTo
			switch (this.options.exportTemplate) {
				case 'weekly':
					dateFrom = this.getMonday()
					dateTo = new Date(dateFrom)
					dateTo.setDate(dateFrom.getDate()+6)
					dateFrom=dateFrom.toISOString().substr(0, 10)
					dateTo=dateTo.toISOString().substr(0, 10)
					break;
				case 'monthly':
					dateFrom = this.datePicker.month+"-01"
					dateTo = (new Date((new Date(dateFrom)).getFullYear(), (new Date(dateFrom)).getMonth()+1, 1)).toISOString().substr(0, 10);
					break;
				case 'range':
					if(this.datePicker.range[1]){
						if(this.datePicker.range[0]<this.datePicker.range[1]){
							dateFrom=this.datePicker.range[0]
							dateTo=this.datePicker.range[1]
						}
						else{
							dateFrom=this.datePicker.range[1]
							dateTo=this.datePicker.range[0]
						}
					}					
					else{
						dateFrom=dateTo=this.datePicker.range[0]
					}
					break;
				default:
					console.log("Select template error - invalid value")
			}
			
			let exportOptions={
				dateFrom: dateFrom,
				dateTo: dateTo,
				workersToExport: this.options.workersToExport
			}

			this.$emit("export-data", exportOptions);
		},

		displayWeek(){
			let monday=this.getMonday()
			let sunday = new Date(monday)
			sunday.setDate(monday.getDate()+6)
			return this.dateString(monday) +" - "+ this.dateString(sunday)
		},
		displayMonth(){
			let string = this.datePicker.month
			return string.substr(5, 2)+"/"+string.substr(0, 4)
		},
		getMonday(){
			let monday = new Date(this.datePicker.date)
			let currentWeekDay=monday.getDay()
			if (currentWeekDay==0){
				monday.setDate(monday.getDate()-7)
			}
			if(currentWeekDay!=1){
				monday.setDate(monday.getDate()-(currentWeekDay-1))
			}
			return monday;
		},
		dateString(date){
			let month = date.getMonth()+1
			if(month<10){
				month = "0"+month
			}
			let day= date.getDate()
			if(day<10){
				day = "0"+day
			}
			return day+"/"+month+"/"+date.getFullYear()
		},
	},
};
</script>

<style>
.actionButtonsWrapper{
	display: flex;
	justify-content: flex-end;
}

.v-btn{
	width: 100px;
}


</style>
