<template>
    <v-dialog 
	v-model="dialog" 
	max-width="500"
	persistent>
		<v-card >
			<v-card-title>
				Add card scans
			</v-card-title>

			<v-divider></v-divider>

			<v-card-text class="pt-3">
                <v-text-field
                    v-model="selectedWorker"
                    label="Enter worker id"
                ></v-text-field>
			
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="grey"
					class="mr-0 mt-0"
					text
					@click="dialog=false"
				>Cancel</v-btn>
				<v-btn router-link :to="{name: 'recordDetails', params: {date: new Date(date), title: 'Worker '+ selectedWorker, worker_id: parseInt(selectedWorker) }}"
					color="primary"
					class="mr-0 mt-0"
					text
                    :disabled="!selectedWorker"
					@click="dialog=false"
				>OK</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
// import axios from 'axios'
// import appConfig from '@/app_config'

export default {

	name: 'AddCardScansDialog',

	data: function(){
		return {
			dialog: false,
			selectedWorker: null,
		}
	},
	props: {
        date: String
    },
	computed: {
		
	},
    mounted(){
		// console.log(this.datePicker.range)
    },
	methods: {
	},
};
</script>

<style>
.actionButtonsWrapper{
	display: flex;
	justify-content: flex-end;
}

.v-btn{
	width: 100px;
}


</style>
