<template>
	<div>
		<div class="main">
			<div class="selectDate">
				<div class="selectDateWrapper hidden-md-and-up">
					<div class="day" @click="selectDay(-3)">{{dateString(getDay(date,-3)).weekDay}}<br>{{dateString(getDay(date,-3)).date}}</div>
					<div class="day" @click="selectDay(-2)">{{dateString(getDay(date,-2)).weekDay}}<br>{{dateString(getDay(date,-2)).date}}</div>
					<div class="day" @click="selectDay(-1)">{{dateString(getDay(date,-1)).weekDay}}<br>{{dateString(getDay(date,-1)).date}}</div>
					<div class="day selectedDay" >{{dateString(getDay(date,0)).weekDay}}<br>{{dateString(getDay(date,0)).date}}</div>
					<div class="day" @click="selectDay(1)">{{dateString(getDay(date,1)).weekDay}}<br>{{dateString(getDay(date,1)).date}}</div>
					<div class="day" @click="selectDay(2)">{{dateString(getDay(date,2)).weekDay}}<br>{{dateString(getDay(date,2)).date}}</div>
					<div class="day" @click="selectDay(3)">{{dateString(getDay(date,3)).weekDay}}<br>{{dateString(getDay(date,3)).date}}</div>
					<div class="iconWrapper" slot="activator">
						<v-dialog
							ref="dialog"
							v-model="modal"
							:return-value.sync="date"
							persistent
							max-width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon 
								class="primary--text" 
								v-bind="attrs"
								v-on="on">
								mdi-calendar-month
								</v-icon>
							</template>
							<v-date-picker
							v-model="date"
							scrollable
							>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="modal = false"
							>
								Cancel
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="$refs.dialog.save(date)"
							>
								OK
							</v-btn>
							</v-date-picker>
						</v-dialog>
					</div>
				</div>
			</div>

			<div class="filterWrapper">
				<div class="selectWeekWrapper hidden-sm-and-down" slot="activator">
					<v-icon @click="changeWeek(-1)">mdi-chevron-left</v-icon>
					<v-dialog
						ref="dialog"
						v-model="modal"
						:return-value.sync="date"
						persistent
						max-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field 
								outlined 
								readonly 
								v-bind="attrs"
								v-on="on"
								:value="getWeek()"
								style="margin-top: -12px"
							>
							</v-text-field>
						</template>
						<v-date-picker
						v-model="date"
						scrollable
						>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="modal = false"
							>
								Cancel
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="$refs.dialog.save(date)"
							>
								OK
							</v-btn>
						</v-date-picker>
					</v-dialog>
					<v-icon @click="changeWeek(1)">mdi-chevron-right</v-icon>
				</div>

				<!--<v-select class="mr-5" 
					v-model="selectedStatus"
					:items="dropdownStatus"
					label="Status"
				></v-select>-->
				
				<v-text-field
					v-model="search"
					class="mr-5"
					dense
					outlined
					:label="lviews.searchWorker"
					append-icon="mdi-magnify"
					@keyup.enter="searchRecords"
					style="border-radius: 20px; height: 40px; width: 60%;"
				></v-text-field>


				<v-select class="ml-5"
					v-model="selectedProject"
					:items="dropdownProjects"
					item-text="name"
                    item-value="id"
					:label="lviews.project"
				></v-select>
					
				<!-- <div class="hidden-sm-and-down" style="width: 350px;  margin-left: 10px;">
					<v-checkbox 
					v-model="checkbox"
					dense
					label="Tylko z uwagami?">
					</v-checkbox>
				</div> -->

				<!-- <v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-icon class="ml-2 hidden-md-and-up" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
					</template>

					<v-list dense class="pa-0">
						<v-list-item  class="pl-2">
							<v-checkbox 
							v-model="checkbox"
							dense
							label="Tylko z uwagami?" class="mt-2" >
							</v-checkbox>
						</v-list-item>
					</v-list>
				</v-menu> -->

				<v-btn
					class="mx-1 hidden-sm-and-down"
					width="60px"
					text
					:disabled="!displayListDesktop.length"
					@click="openExportDialog"
				>
					<v-icon large color="primary">mdi-tray-arrow-down</v-icon>
				</v-btn>

			</div>

			<!-- Mobile view -->
			<v-list dense class="hidden-md-and-up">
				<v-divider></v-divider>
				<div v-for="worker in workersFromDay" v-bind:key="worker">
					<div v-if="!checkbox || workerDailyLeaves(worker, date).alert || hasWorkerUndefinedTypeRecords(worker, new Date(date))">
						<v-list-item class="pt-2" router-link :to="{name: 'recordDetails', params: {date: new Date(date), title: worker, worker_id: getWorkerId(worker) }}">
							<v-list-item-content class="pa-0">
								<v-list-item-title>{{worker}}</v-list-item-title>
							</v-list-item-content>
							<div style="display: flex; align-items: center;" v-if="dailyWorkersRecords(worker, new Date(date)).length>0 && (workerDailyLeaves(worker, date).alert || workerDailyLeaves(worker, date).leaves.length>0 || hasWorkerUndefinedTypeRecords(worker, new Date(date)))">							
								<div v-if="hasWorkerUndefinedTypeRecords(worker, new Date(date))">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon 
											class="red--text"
											dense
											v-bind="attrs"
											v-on="on">mdi-help-circle
											</v-icon>
										</template>
										<span>{{lviews.undefinedRecord}}</span>
									</v-tooltip>
								</div>
								<div class="px-1 " v-for="item in workerDailyLeaves(worker, date).leaves" :key="item.id">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<p 
											class="px-1 absentShortLabelWrapper ma-0" 
											v-bind:style="{'color': getLeaveTypeById(item.leave_type).color, 'background': getLeaveTypeById(item.leave_type).bgcolor}"
											v-bind="attrs"
											v-on="on">{{getLeaveTypeById(item.leave_type).code}}</p>
										</template>
										<span>{{leaveDescription(item)}}</span>
									</v-tooltip>
								</div>
								<div  v-if="workerDailyLeaves(worker,date).alert">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon 
											class="alert--text"
											dense
											v-bind="attrs"
											v-on="on">mdi-alert
											</v-icon>
										</template>
										<span>{{workerDailyLeaves(worker, date).alertMessage}}</span>
									</v-tooltip>
								</div>
							</div>
						</v-list-item>
						<v-list-item v-if="dailyWorkersRecords(worker, new Date(date)).length">
							<v-list-item >
								<v-list-item-icon class="mr-2">
									<v-icon v-if="findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).firstIn.hour" class="primary--text" dense>mdi-login-variant</v-icon>
									<v-icon v-if="!findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).firstIn.hour" class="grey--text" dense>mdi-login-variant</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-if="findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).firstIn.hour">{{fixHourString(findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).firstIn.hour)}}</v-list-item-title>
									<v-list-item-title v-if="!findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).firstIn.hour" class="grey--text">{{fixHourString(findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).firstIn.hour)}}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-icon class="mr-2">
									<v-icon v-if="findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).lastOut.hour" class="primary--text" dense>mdi-logout-variant</v-icon>
									<v-icon v-if="!findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).lastOut.hour" class="grey--text" dense>mdi-logout-variant</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-if="findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).lastOut.hour">{{fixHourString(findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).lastOut.hour)}}</v-list-item-title>
									<v-list-item-title v-if="!findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).lastOut.hour" class="grey--text">{{fixHourString(findFirstInLastOut(dailyWorkersRecords(worker, new Date(date))).lastOut.hour)}}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-icon class="mr-2">
									<v-icon class="primary--text" dense>mdi-clock-time-nine-outline</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{timeToString(workTimeDaily(dailyWorkersRecords(worker, new Date(date))))}}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item>
						<v-list-item class="pt-1" style="font-size: 14px" v-if="dailyWorkersRecords(worker, new Date(date)).length==0 && workerDailyLeaves(worker, date).leaves.length>0">
							<div v-for="item in workerDailyLeaves(worker, date).leaves" :key="item.id">								
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<p 
										class="px-1"
										v-bind:style="{'color': getLeaveTypeById(item.leave_type).color, 'background': getLeaveTypeById(item.leave_type).bgcolor}"
										v-bind="attrs"
										v-on="on"
										v-if="getLeaveTypeById(item.leave_type).name">{{getLeaveTypeById(item.leave_type).name.toUpperCase()}}
										</p>
									</template>
									<span>{{leaveDescription(item)}}</span>
								</v-tooltip>
							</div>
						</v-list-item>
						<v-divider></v-divider>
					</div>
				</div>
			</v-list>

			<!-- Desktop view -->
			<div class='records-dekstop-container'>
				<v-list dense class="hidden-sm-and-down records-table">
					<div class="tableRow">
						<v-list-item class="tableHeader">
								<v-checkbox class="ma-0 pa-0"
									style="height: 25px"
									dense
									v-model="mainCheckbox"
									:indeterminate="mainCheckboxIndeterminate"
									@click="()=>{switchCheckAllWorkers()}"
								></v-checkbox>
							<v-list-item-content>
								<v-list-item-title>{{lviews.selected}}: {{selectedRecords.length}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<div v-for="n in 7" v-bind:key="n" class="tableHeader smallCell" >
						<v-list-item 
						v-bind:style="{
							'background': dateString(getDay(getMonday(), n-1)).date==dateString(new Date()).date? '#2962ff': '#FBC02D',
							'color': dateString(getDay(getMonday(), n-1)).date==dateString(new Date()).date? 'white': 'black'}"
						>		
							<v-list-item-content>
								<v-list-item-title>{{dateString(getDay(getMonday(), n-1)).weekDay}}<br>{{dateString(getDay(getMonday(), n-1)).date}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						</div>
						<v-list-item class="tableHeader summaryColumn pl-1 pr-1">
							<v-list-item-content>
								<v-list-item-title>{{lviews.summary}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
					<div  v-for="worker in displayListDesktop" v-bind:key="worker.workerId">
					<div class="tableRow" v-if="!checkbox || hasWorkerRecordsWithAlert(worker.workerFullName) || hasWorkerUndefinedTypeRecordsWeekly(worker.workerFullName)">
						
						<div class="tableCell"
							style="display: flex; flex-direction: column; justify-content: center"
						>
						<div style="display:flex; align-items: center;">
							<v-checkbox 
								v-model="worker.checked"
								class="mt-0 ml-3 pa-0"
								style="height: 25px"
								dense
								@click="()=>{updateMainCheckbox()}"
							></v-checkbox>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<p 
										class="font-weight-medium ml-2 mt-4 mr-2 userFullNameTitle"
										v-on="on"
										v-bind="attrs"
									>{{worker.workerFullName}}</p>
								</template>

								<span>{{ worker.workerFullName }}</span>
							</v-tooltip>
						</div>
						</div>

						<v-list-item class="tableCell smallCell px-1 " v-for="n in 7" v-bind:key="n" router-link :to="{name: 'recordDetails', params: {date: getDay(getMonday(), n-1), title: worker.workerFullName, worker_id: worker.workerId} }">
							<!-- <div style="display: flex;" v-if="!checkbox || workerDailyLeaves(worker, getDay(getMonday(), n-1).toISOString().substr(0, 10)).alert"> -->
								<v-list dense v-if="worker.records[getDay(getMonday(), n-1).toISOString().substr(0, 10)]">
									<v-list-item class="pa-0">
										<v-list-item-icon class="mr-1 my-1" >
											<v-icon class="primary--text" dense>mdi-login-variant</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="ma-0 pa-0">
											<v-list-item-title>{{worker.records[getDay(getMonday(), n-1).toISOString().substr(0, 10)].firstIn}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item class="pa-0">
										<v-list-item-icon class="mr-1 my-1">
											<v-icon class="primary--text" dense>mdi-logout-variant</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="ma-0 pa-0">
											<v-list-item-title>{{worker.records[getDay(getMonday(), n-1).toISOString().substr(0, 10)].lastOut}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									
									<v-list-item class="pa-0">
										<v-list-item-icon class="mr-1 my-1">
											<v-icon class="primary--text" dense>mdi-clock-time-nine-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{worker.records[getDay(getMonday(), n-1).toISOString().substr(0, 10)].worktime}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
								<!-- Small icon list - if user has some daily records -->
								<v-list class="ml-3" v-if="worker.records[getDay(getMonday(), n-1).toISOString().substr(0, 10)] && (workerDailyLeaves(worker.workerFullName, getDay(getMonday(), n-1).toISOString().substr(0, 10)).alert || workerDailyLeaves(worker.workerFullName, getDay(getMonday(), n-1).toISOString().substr(0, 10)).leaves.length>0 || hasWorkerUndefinedTypeRecords(worker.workerFullName, getDay(getMonday(), n-1)))">
									<!-- jeśli jest nieprzypisane zdarzenie -->
									<v-list-item class="pa-0 mb-1" v-if="hasWorkerUndefinedTypeRecords(worker.workerFullName, getDay(getMonday(), n-1))">
										<v-list-item-icon class="ma-0">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon 
													class="red--text"
													dense
													v-bind="attrs"
													v-on="on">mdi-help-circle
													</v-icon>
												</template>
												<span>{{lviews.undefinedRecord}}</span>
											</v-tooltip>
										</v-list-item-icon>
									</v-list-item>
									<v-list-item style="display:flex; justify-content: flex-end;" class="pa-0 mb-1" v-if="workerDailyLeaves(worker.workerFullName, getDay(getMonday(), n-1).toISOString().substr(0, 10)).alert">
										<v-list-item-icon class="ma-0" >
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon 
													class="alert--text"
													dense
													v-bind="attrs"
													v-on="on">mdi-alert
													</v-icon>
												</template>
												<span>{{workerDailyLeaves(worker.workerFullName, getDay(getMonday(), n-1).toISOString().substr(0, 10)).alertMessage}}</span>
											</v-tooltip>
										</v-list-item-icon>
									</v-list-item>
									<v-list-item class="green pa-0 mb-1" v-for="item in workerDailyLeaves(worker.workerFullName, getDay(getMonday(), n-1).toISOString().substr(0, 10)).leaves" :key="item.id">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<p 
												class="ma-0 px-1 absentShortLabelWrapper" 
												v-bind:style="{'color': getLeaveTypeById(item.leave_type).color, 'background': getLeaveTypeById(item.leave_type).bgcolor}"
												v-bind="attrs"
												v-on="on">{{getLeaveTypeById(item.leave_type).code}}</p>
											</template>
											<span>{{leaveDescription(item)}}</span>
										</v-tooltip>
									</v-list-item>
								</v-list>

								<div class="absentLongLabelWrapper" v-if="!worker.records[getDay(getMonday(), n-1).toISOString().substr(0, 10)] && workerDailyLeaves(worker.workerFullName, getDay(getMonday(), n-1).toISOString().substr(0, 10)).leaves.length>0">
									<div v-for="item in workerDailyLeaves(worker.workerFullName, getDay(getMonday(), n-1).toISOString().substr(0, 10)).leaves" :key="item.id">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<p 
												v-bind:style="{'color': getLeaveTypeById(item.leave_type).color, 'background': getLeaveTypeById(item.leave_type).bgcolor}"
												v-bind="attrs"
												v-on="on"
												v-if="getLeaveTypeById(item.leave_type).name">{{getLeaveTypeById(item.leave_type).name.toUpperCase()}}
												</p>
											</template>
											<span>{{leaveDescription(item)}}</span>
										</v-tooltip>
									</div>

								</div>
							<!-- </div> -->
						</v-list-item>

						<v-list-item class="tableCell smallCell pl-1">
							<v-list dense >
								<v-list-item class="pa-0">
									<v-list-item-icon class="mr-1">
										<v-icon class="primary--text" dense>mdi-clock-time-nine-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{timeToString(workTimeWeekly(worker.workerFullName))}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-list-item>
						</div>
					</div>
				</v-list>
			</div>

			<v-container class="hidden-sm-and-down mt-10 tableFooter">
				<v-row 
				align="center"
				justify="end"
				class="pr-16"
				>
				<span class="mr-5">{{lviews.itemsPerPage}}:</span>
				<div class="selectWrapper">
					<v-select class="mr-5" 
					v-model="itemsPerPage"
					:items="itemsPerPageArray"
					dense
					></v-select>
				</div>

				<span
					class="mr-4"
				>
					{{firstItem}} - {{lastItem}} {{lviews.of}} {{totalItems}} {{lviews.elements}}
				</span>
				
				<v-icon @click="formerPage">mdi-chevron-left</v-icon>
				<v-icon @click="nextPage" class="mr-5">mdi-chevron-right</v-icon>
				</v-row>
			</v-container>
		</div>

		<!-- add records to another worker button -->
		<!-- <v-btn
			color="primary"
			fab
			fixed
			bottom
			right
			@click="openAddRecordsDialog"
		>
			<v-icon class="">mdi-plus</v-icon>
		</v-btn> -->

		<!-- DIALOGS -->

		<ExportTimesheetsDialog ref="exportTimesheetsDialog"
			:date="date"
			:checkedWorkersNumber="selectedRecords.length"
			:allWorkersNumber="displayListDesktop.length"
			@export-data="exportTimesheets"
		></ExportTimesheetsDialog>

		<!-- add records to another worker dialog -->
		<AddCardScansDialog ref="addCardScansDialog"
			:date="date"
		></AddCardScansDialog>

		<!-- Error dialog -->
		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
	

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'
import ExportTimesheetsDialog from './ExportTimesheetsDialog.vue'
import AddCardScansDialog from './AddCardScansDialog.vue'

export default {
	name: 'Records',

	components: {
		ExportTimesheetsDialog,
		AddCardScansDialog,
	},

	data: () => ({
		windowWidth: window.innerWidth,
		title: 'Timesheets',
		modal: false,
		mainCheckbox: false,
		mainCheckboxIndeterminate: false,
		checkbox: false,
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		currentDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		page: 1,
		itemsPerPage: 5,
		totalItems: 0,
		itemsPerPageArray: [5, 10, 20],
		selectedProject: -1,
		dropdownProjects: [],
		projects: [],
		selectedWorkerProjects: [],
		selectedWorker: null,
		search: null,
		dates: [],
		filteredRecordsToDisplay:[],
		workersLeavesAssignments: [
            /*{
                {
                    date_from: "2022-01-11"
                    date_to: "2022-01-13"
                    id: 1
                    leave_type: 1
                    project: 1
                    status: 0
                    worker: 5
                    worker_full_name: "Kochanowski Jan"
                }
            }*/
        ],
		leavesTypes: [],
		workersLeaves: {
            /*
            'workerId': {
                date_from: "2022-01-11"
                date_to: "2022-01-13"
                id: 1
                leave_type: 1
                project: 1
                status: 0
                worker: 5
                worker_full_name: "Kochanowski Jan"
            }
            */
        },
		errorDialog: {
			errorTitle: "Error occured",
			errorMessage: "An error occured",
			model: false
		},
		mobileView: false,

        //new from here
        displayListDesktop: [
            /*
            {
                workerId: <Number>
                workerFullName: <String>
                worktimeWeekly: 'HH:MM'
				checked: Boolean
                records: {

                        'YYYY-MM-DD': {
							undefinedRecords: <Boolean>
							dailyLeavesInfo: {
								alert: <Boolean>
								alertMessage: <String>
								leaves: [{
									name: <String>
									color: <String>
									code: <String>
									description: <String>
								}]
							}
							firstIn: 'HH:MM'
							lastOut: 'HH:MM'
							worktime: 'HH:MM'
						}
                }
            }
             */
        ]
	}),
	
	mounted() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.title=this.lviews.pageTitle

		this.selectedProject=this.$store.state.timesheetsCache.selectedProject
		this.search=this.$store.state.timesheetsCache.search
		this.page=this.$store.state.timesheetsCache.page
		this.itemsPerPage=this.$store.state.timesheetsCache.itemsPerPage

		this.getDataFromApi()
		this.onResize()
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
		this.$emit("set-state", "default");
		this.getProjectsFromApi()
		if(localStorage.date){
			this.date=JSON.parse(localStorage.date)
		}
		this.getLeavesTypesFromApi()
		this.getLeavesFromApi()
		this.setTitle();
		this.getGoBackLink();
	},
	computed: {
		lviews: { get: function () { return this.$t('views.timesheets'); } },
		lmessages: { get: function () { return this.$t('commons.messages'); } },
		workersFromDay(){
			let workers=new Set()
			for(let item of this.dates){
				if (this.date in item){
					for(let worker of item[this.date]){
						workers.add(worker.worker_full_name)
					}
					break
			}}
			for(let leave of this.workersLeavesAssignments){
				if(this.date>= leave.date_from && this.date <= leave.date_to){
					if(this.selectedProject!=-1 && this.selectedProject!=null){
						if(leave.project==this.selectedProject){
							workers.add(leave.worker_full_name)
						}
					}
					else{
						workers.add(leave.worker_full_name)
					}
				}
			}
			return [...workers].sort()
		},
		workers(){
			let workers=new Set()
			for (let date of this.dates){
				for(let worker of date[Object.keys(date)[0]]){
					workers.add(worker.worker_full_name)
				}
			}
			for(let leave of this.workersLeavesAssignments){
				if(this.selectedProject!=-1 && this.selectedProject!=null){
					if(leave.project==this.selectedProject){
						workers.add(leave.worker_full_name)
					}
				}
				else{
					workers.add(leave.worker_full_name)
				}
			}
			return [...workers].sort()
		},
		dropdownWorkers(){
			let workers={}
			for (let date of this.dates){
				for(let worker of date[Object.keys(date)[0]]){
					workers[worker.worker_id]=worker.worker_full_name
				}
			}
			for(let leave of this.workersLeavesAssignments){
				workers[leave.worker]=leave.worker_full_name
			}
			return workers
		},
		firstItem(){
			return this.totalItems? (this.page-1)*this.itemsPerPage+1: 0
		},
		lastItem(){
			if(this.totalItems){
				return this.totalItems< this.page*this.itemsPerPage? this.totalItems: this.page*this.itemsPerPage
			}
			return 0		
		},
		selectedRecords(){
			let result = []
			for(let item of this.displayListDesktop){
				if(item.checked){
					result.push(item)
				}
			}
			return result
		}
	},
	methods: {
		onResize(){
			this.windowWidth = window.innerWidth;
			if(this.windowWidth<960)
			{
				this.mobileView=true
			}
			else{
				this.mobileView=false
			}
		},
		setTitle() {
			this.$emit('set-title', this.title);
		},
		getGoBackLink() {
			this.$emit('getGoBackLink', "");
		},
		input(item){
			this.selectedWorker=item
		},
		//Get data from select week
		getDataFromApi(){
			let params = {
				'date_from': this.mobileView? this.date: this.getMonday().toISOString().substr(0, 10),
				'date_to': this.mobileView? this.date: this.getSunday().toISOString().substr(0, 10),	
			}
			if(!this.mobileView){
				params.page= this.page,
				params.per_page= this.itemsPerPage
			}
			// project filter
			if(this.selectedProject!=-1 && this.selectedProject!=null){
				params.projects = this.selectedProject
			}
			// worker filter
			if(this.search!="" && this.search!=null){
				params.personal_data_search = this.search
			}
			this.$emit("set-state",  "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/cards/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: params
			}).then((response) => {
				this.totalItems=response.data.total
				this.dates=response.data.items.dates
				this.displayListDesktop = this.adaptApiResponseRecords(response.data.items.dates)
				this.updateMainCheckbox()
				this.$emit("set-state",  "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				this.handleError(error)
			})
		},
		getProjectsFromApi(){
			this.$emit("set-state",  "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/projects/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				}
			}).then((response) => {
				this.projects=response.data
				this.$emit("set-state",  "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				this.handleError(error)
			})
		},
		getWorkersProjectsFromApi(){
			this.$emit("set-state",  "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/worker-projects/"+this.selectedWorker.workerId,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				}
			}).then((response) => {
				this.selectedWorkerProjects=response.data.projects
				this.$emit("set-state",  "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				this.handleError(error)
			})
		},
		getLeavesTypesFromApi(){
			this.$emit("set-state",  "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/leave-type/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				}
			}).then((response) => {
				this.leavesTypes=response.data
				this.$emit("set-state",  "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				this.handleError(error)
			})
		},
		getLeavesFromApi(){
			this.$emit("set-state",  "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/workers/leaves/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: {
					'date_from': this.getMonday().toISOString().substr(0, 10),
					'date_to': this.getSunday().toISOString().substr(0, 10),
				}
			}).then((response) => {
				this.workersLeavesAssignments=response.data
				this.$emit("set-state",  "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				this.handleError(error)
			})
		},
		handleError(error){
			if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = this.lmessages.networkErrorTitle;
					this.errorDialog.errorMessage = this.lmessages.networkError;
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lmessages.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = this.lmessages.tooManyRequestsErrorTitle;
						this.errorDialog.errorMessage = this.lmessages.tooManyRequestsError;
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = this.lmessages.requestRejectedTitle;
						this.errorDialog.errorMessage = this.lmessages.requestRejected;
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = this.lmessages.internalServerErrorTitle;
						this.errorDialog.errorMessage = this.lmessages.internalServerError;
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = this.lmessages.errorOccuredTitle;
						this.errorDialog.errorMessage = this.lmessages.errorOccured;
						this.errorDialog.model = true;
						return;
				}
		},

		adaptApiResponseRecords(dates){
			let result = []
			for(let item of dates){
				let date = Object.keys(item)[0]
				let dailyRecords = item[date]
				for(let record of dailyRecords){
					// let worker = result.find(i=>i.id==record.worker_id)
					// if (!worker){

					// }
					let workerCardScans=record.card_scans
					let firstInLastOut = this.findFirstInLastOut(workerCardScans)
					let workTimeDaily = this.timeToString(this.workTimeDaily(workerCardScans))
					let dailyInfo = {
						// date: date,
						// undefinedRecords: jakaś funkcja?
						// dailyLeavesInfo: jakaś funkcja
						firstIn: firstInLastOut.firstIn.hour? firstInLastOut.firstIn.hour: '---',
						lastOut: firstInLastOut.lastOut.hour? firstInLastOut.lastOut.hour: '---',
						worktime: workTimeDaily
					}

					let worker = result.find(i=>i.workerId==record.worker_id)
					if (worker){
						worker.records[date]=dailyInfo
					}
					else{
						let newWorker = {
							workerId: record.worker_id,
							workerFullName: record.worker_full_name,
							// worktimeWeekly: 'HH:MM'
							checked: false,
							records: {}
						}
						newWorker.records[date]=dailyInfo
						result.push(newWorker)
					}
				}
			}
			return result
		},
		workerDailyLeaves(worker, date){
			if(this.workersLeaves){
				let workerId = this.getWorkerId(worker)
				let workerLeaves = this.workersLeaves[workerId]
				let dailyLeaves=[]
				if( workerLeaves && workerLeaves.length>0){
					for(let leave of workerLeaves){
						if(date>=leave.date_from && date<= leave.date_to){
							dailyLeaves.push(leave)
						}
					}
				}

				let workerDailyInfo = {
					alert: false,
					alertMessage: "",
					noRecords: this.dailyWorkersRecords(worker, new Date(date)).length==0? true: false,
					leaves: dailyLeaves
				}
				let alertMessageArr = []

				if(!workerDailyInfo.noRecords){
					let recordsByProject=this.splitRecordsByProjects(this.dailyWorkersRecords(worker, new Date(date)))
					if(recordsByProject && date!=this.currentDay){
						for(let item in recordsByProject){
							let loginLogoutPairs = this.loginLogoutPairs(recordsByProject[item])
							if(loginLogoutPairs){
								for(let pair of loginLogoutPairs){
									if(!pair.login.hour){
										workerDailyInfo.alert=true
										alertMessageArr.push(this.lviews.missingEntering)
									}
									if(!pair.logout.hour){
										workerDailyInfo.alert=true
										alertMessageArr.push(this.lviews.missingLeaving)
									}
								}
							}
						}
					}
					if(this.timeToString(this.workTimeDaily(this.dailyWorkersRecords(worker, new Date(date))))>"12:00"){
						workerDailyInfo.alert=true
						alertMessageArr.push(this.lviews.worktimeOver12)
					}
				}
				if(dailyLeaves.length>0){
					for(let leave of dailyLeaves){
						if(!workerDailyInfo.noRecords && this.getLeaveTypeById(leave.leave_type).code!="D"){
							workerDailyInfo.alert=true
							alertMessageArr.push(this.lviews.leaveDuringWork)
						}
					}
				}

				workerDailyInfo.alertMessage=this.alertMessage(alertMessageArr)


				return workerDailyInfo
			}
		},
		hasWorkerRecordsWithAlert(worker){
			for(let i=1;i<=7;i++){
				if(this.workerDailyLeaves(worker, this.getDay(this.getMonday(), i-1).toISOString().substr(0, 10)).alert){
					return true
				}
			}
			return false
		},
		hasWorkerUndefinedTypeRecords(worker,date){
			let records = this.dailyWorkersRecords(worker,date)
			if(records){
				for(let item of records){
					if(item.type==2) return true
				}
			}
			return false
		},
		hasWorkerUndefinedTypeRecordsWeekly(worker){
			for(let i=1;i<=7;i++){
				let records = this.dailyWorkersRecords(worker, this.getDay(this.getMonday(), i-1))
				if(records){
					for(let item of records){
						if(item.type==2) return true
					}
				}
			}
			return false
		},
		getLeaveTypeById(id){
			if(this.leavesTypes){
				for(let type of this.leavesTypes){
					if(type.id==id){
						return type
					}
				}
			}
			return {}
		},
		leaveDescription(item){
			let description=""
			if(item){
				let type = this.getLeaveTypeById(item.leave_type)
				description = type.name
				if(item.date_from!=item.date_to){
					description+=" "+this.lviews.since+" "
					description+=item.date_from.substr(8)
					description+="."
					description+=item.date_from.substr(5,2)
					description+="."
					description+=item.date_from.substr(0,4)
					description+=" "+this.lviews.to+" "
					description+=item.date_to.substr(8)
					description+="."
					description+=item.date_to.substr(5,2)
					description+="."
					description+=item.date_to.substr(0,4)
				}
			}
			return description
		},
		alertMessage(arr){
			let alert = ""
			if(arr.length>0){
				alert+=arr[0].charAt(0).toUpperCase() + arr[0].slice(1)
				if(arr.length>1){
					for(let i=1;i<arr.length;i++){
						alert+=", "
						alert+=arr[i]
					}
				}
			}
			return alert
		},
		getNameWeekDay(number){
			return this.lviews.weekDays[number]
			// switch(number){
			// 	case 0: 
			// 		return 'SUN'
			// 	case 1: 
			// 		return 'MON'
			// 	case 2: 
			// 		return 'TUE'
			// 	case 3: 
			// 		return 'WED'
			// 	case 4: 
			// 		return 'THU'
			// 	case 5: 
			// 		return 'FRI'
			// 	case 6: 
			// 		return 'SAT'
			// }
		},
		//Returns date 'number' days before/after given date
		getDay(date, number){
			let day = new Date(date)
			day.setDate(new Date(date).getDate() + number)
			return day
		},
		//returns date of Monday in current week
		getMonday(){
			let monday = new Date(this.date)
			let currentWeekDay=monday.getDay()
			if (currentWeekDay==0){
				monday.setDate(monday.getDate()-7)
			}
			if(currentWeekDay!=1){
				monday.setDate(monday.getDate()-(currentWeekDay-1))
			}
			return monday;
		},
		//returns date of Sunday in current week -> temporary function
		getSunday(){
			let monday = this.getMonday()
			let sunday = new Date(monday)
			sunday.setDate(monday.getDate()+6)
			return sunday;
		},
		//returns string describing current week in format DD.MM-DD.MM.YYYY
		getWeek(){
			let monday=this.getMonday()
			let sunday = new Date(monday)
			sunday.setDate(monday.getDate()+6)
			return this.dateString(monday).date +" - "+ this.dateString(sunday).date + "." + sunday.getFullYear()

		},
		//skip to the next(number==1) or last(number==-1) week
		changeWeek(number){
			let currentDate=new Date(this.date)
			switch(number){
				case 1:
					currentDate.setDate(new Date(this.date).getDate() + 7)
					break
				case -1:
					currentDate.setDate(new Date(this.date).getDate() -7)
					break
			}
			this.date=currentDate.toISOString().substr(0, 10)
		},
		getWorkerId(workerFullName){
			if(this.dates){
				for(let date of this.dates){
					for(let worker of date[Object.keys(date)[0]]){
						if(worker.worker_full_name==workerFullName){
							return worker.worker_id
						}
					}
				}
			}
			if(this.workersLeavesAssignments){
				for(let item of this.workersLeavesAssignments){
					if(item.worker_full_name==workerFullName){
						return item.worker
					}
				}
			}
		},
		getProjectId(projectName){
			if(this.projects){
				for(let project of this.projects){
					if(project.name==projectName){
						return project.id
					}
				}
			}
		},
		selectDay(number){
			let currentDate=new Date(this.date)
			currentDate.setDate(new Date(this.date).getDate() + number)
			this.date=currentDate.toISOString().substr(0, 10)
		},
		//returns object contains date in DD.MM format and name of weekDay
		dateString(date){
			let month = date.getMonth()+1
			if(month<10){
				month = "0"+month
			}
			return {
				date: date.getDate() + "."+month,
				weekDay: this.getNameWeekDay(date.getDay())
			}
		},
		nextPage () {
			if(this.lastItem<this.totalItems){
				this.page++
				this.getDataFromApi()
			}
		},
		formerPage () {
			if(this.firstItem>1){
				this.page--
				this.getDataFromApi()
			}
		},
		//Returns array of records of given worker from given date
		dailyWorkersRecords(worker, date){
			date=date.toISOString().substr(0, 10)
			let currentDate = this.dates.find(item => Object.keys(item)[0]==date)
			if(!currentDate){
				return []
			}
			let currentDateArray=currentDate[Object.keys(currentDate)[0]]
			let currentWorker=currentDateArray.find(item=>item.worker_full_name==worker)
			if(!currentWorker){
				return []
			}
			return currentWorker.card_scans
		},
		
		splitRecordsByProjects(records){
			let recordsByProjects = {}
			if(records){
				for(let item of records){
					if(!recordsByProjects[item.project]){
						recordsByProjects[item.project]=[]
					}
					recordsByProjects[item.project].push(item)
				}
			}
			return recordsByProjects
		},
		workTimeProject(pair){
			if(!pair.login.hour || !pair.logout.hour){
				return 0
			}
			var time_start = new Date();
			var time_end = new Date();
			if(pair.login.hour>pair.logout.hour){
				time_end.setDate(time_end.getDate()+1)
			}
			var value_start = pair.login.hour.split(':');
			var value_end = pair.logout.hour.split(':');
			time_start.setHours(value_start[0], value_start[1], 0, 0)
			time_end.setHours(value_end[0], value_end[1], 0, 0)
			return (time_end-time_start)
		},
		//Counting daily time of work
		workTimeDaily(cardScans){
			let recordsByProject=this.splitRecordsByProjects(cardScans)
			let time=0
			if(recordsByProject){
				for(let item in recordsByProject){					
					let loginLogoutPairs = this.loginLogoutPairs(recordsByProject[item])
					if(loginLogoutPairs){
						for(let pair of loginLogoutPairs){
							time +=this.workTimeProject(pair)
						}
					}
				}
			}
			return time
		},
		workTimeWeekly(worker){
			let monday = this.getMonday()
			let time=0
			for(let i=0;i<7;i++){
				let day = this.getDay(monday, i)
				let records = this.dailyWorkersRecords(worker,day)
				time+=this.workTimeDaily(records)
			}
			return time
		},
		//Convert miliseconds to string HH:MM
		timeToString(time){
			if(!time){
				return '---'
			}
			let hours = Math.floor(time/1000/3600)
			let minutes = ((time%3600000)/1000/60)
			if(hours<10){
				hours="0"+hours
			}
			if(minutes<10){
				minutes="0"+minutes
			}	
			return hours+":"+minutes
		},
		loginLogoutPairs(cardScans){
			let pairs=[]
			for(let i=0;i<cardScans.length;i++){
				let pair={
					login: "",
					logout: ""
				}
				if(cardScans[i].type==1){
					pair.login={}
					pair.logout=cardScans[i]
					pairs.push(pair)
					continue
				}
				if(cardScans[i].type==0){
					pair.login=cardScans[i]
					if(i==cardScans.length-1){
						pair.logout={}
						pairs.push(pair)
						break
					}
					if(cardScans[i+1].type==1){
						pair.logout=cardScans[i+1]
						pairs.push(pair)
						i++
						continue
					}
					pair.logout={}
					pairs.push(pair)
				}
			}
			return pairs
		},
		fixHourString(hour){
			if(!hour){
				return '---'
			}
			let splitHour=hour.split(':')
			let hours=splitHour[0]
			let minutes=splitHour[1]
			if(hours.length<2){
				hours='0'+hours
			}
			if(minutes.length<2){
				minutes='0'+minutes
			}
			return hours+":"+minutes
		},
		//Finding first daily entering and last leaving after first entering
		findFirstInLastOut(records){
			let firstIn={}
			let lastOut={}
			for(let item of records){
				if(item.type==0){
					firstIn=item
					break
				}
			}
			let index=records.indexOf(firstIn)
			for(let i=records.length-1;i>index;i--){
				if(records[i].type==1){
					lastOut= records[i]
					break
				}
			}
			return {
				firstIn: firstIn,
				lastOut: lastOut
			}
		},
		filterRecordsByProject(project){
			let filteredRecords=[]
			if(this.dates){
				for(let date of this.dates){
					let newDate=[]
					for(let worker of date[Object.keys(date)[0]]){
						let newWorker = {
							"worker_id": worker.worker_id,
							"worker_full_name": worker.worker_full_name,
							"card_scans": []
						}
						for(let record of worker.card_scans){
							if(record.project==this.getProjectId(project)){
								newWorker.card_scans.push(record)
							}
						}
						if(newWorker.card_scans.length>0){
							newDate.push(newWorker)
						}
					}
					if(newDate.length>0){
						let objectDate = {}
						objectDate[Object.keys(date)[0]]=newDate
						filteredRecords.push(objectDate)
					}
				}
			}
			return filteredRecords
		},
		setDropdownProjects(){
			let ddprojects = [{
				name: this.lviews.all,
				id: -1
			}]
			if(this.selectedWorker){
				if(this.selectedWorkerProjects){
					for(let project of this.selectedWorkerProjects){
						ddprojects.push({
							name: project.project_name,
							id: project.id
						})
					}
				}
			}
			else{
				if(this.projects){
					for(let project of this.projects){
						ddprojects.push({
							name: project.name,
							id: project.id
						})
					}
				}
			}
			this.dropdownProjects= ddprojects
		},
		searchRecords(){
			this.page=1
			this.getDataFromApi()
		},
		updateTimesheetsCacheStore(){
			this.$store.commit({
				type: 'updateTimesheetsCache',
				page: this.page,
				itemsPerPage: this.itemsPerPage,
				search: this.search,
				project: this.selectedProject
			})
		},

		switchCheckAllWorkers(){
			for(let item of this.displayListDesktop){
				item.checked=this.mainCheckbox
				this.mainCheckboxIndeterminate=false
			}
		},
		updateMainCheckbox(){
			if(this.displayListDesktop){
				if(this.selectedRecords.length==0){
					this.mainCheckbox=false
					this.mainCheckboxIndeterminate=false
				}
				else if(this.selectedRecords.length==this.displayListDesktop.length){
					this.mainCheckbox=true
					this.mainCheckboxIndeterminate=false
				}
				else{
					this.mainCheckbox=false
					this.mainCheckboxIndeterminate=true
				}
			}
		},

		//export dialog methods
		openExportDialog(){
			this.$refs.exportTimesheetsDialog.openDialog();
		},
		exportTimesheets(exportOptions){
			let workersIds=[]
			let arr= exportOptions.workersToExport=="all"? this.displayListDesktop: this.selectedRecords;
			for(let item of arr){
				workersIds.push(item.workerId)
			}
			let params={
				date_from: exportOptions.dateFrom,
				date_to: exportOptions.dateTo,
				workers: workersIds.toString(),
				language: "en" //do zmiany
			}
			if(this.selectedProject!=-1){
				params.projects=this.selectedProject
			}

			this.$emit("set-state",  "LOADING_OVERLAYER");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/excel/",
				responseType: 'blob',
				headers: {
					// "Content-Type": "xlsx",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: params
			}).then((response) => {
				const url = URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute(
				'download',
				`Timesheets_${exportOptions.dateFrom}-${exportOptions.dateTo}.xlsx`
				)
				document.body.appendChild(link)
				link.click()
				this.$emit("set-state",  "DEFAULT");
				this.$refs.exportTimesheetsDialog.closeDialog();
				this.$emit("display-success",  this.lmessages.dataExportedSuccesfully);
			})
			.catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				this.handleError(error)
			})

		},

		//add card scans dialog methods
		openAddRecordsDialog(){
			this.$refs.addCardScansDialog.dialog=true;
		},
	},
	watch: {
		mobileView(){
			this.getDataFromApi()
		},
		date() {
			this.getDataFromApi()
			this.getLeavesFromApi()
			localStorage.date=JSON.stringify(this.date)
			this.page=1
		},
		selectedProject: function(){
			this.page=1
			this.getDataFromApi()
			this.updateTimesheetsCacheStore()	
		},
		search: function(){
			if(this.search==""){
				this.page=1
				this.getDataFromApi()
			}
			this.updateTimesheetsCacheStore()
		},
		selectedWorkerProjects: function(){
			this.setDropdownProjects()
		},
		page(){
			this.updateTimesheetsCacheStore()
		},
		itemsPerPage(){
			this.page=1
			this.getDataFromApi()
			this.updateTimesheetsCacheStore()
		},
		projects: function(){
			this.setDropdownProjects()
		},
		workersLeavesAssignments: function(){
			let leaves={}
			for(let i=0;i<this.workersLeavesAssignments.length;i++){
				if(! (this.workersLeavesAssignments[i].worker in leaves)){
					leaves[this.workersLeavesAssignments[i].worker]=[]
				}
				leaves[this.workersLeavesAssignments[i].worker].push(this.workersLeavesAssignments[i])
			}
			this.workersLeaves=leaves
			this.$forceUpdate();
		},
	},
};
</script>

<style scoped>

.records-dekstop-container {
	max-width: 100%;
	overflow-x: auto;
}

.records-table {
	min-width: 800px;
}

.selectDateWrapper{
	display: grid;
	grid-template-columns: repeat(7, 1fr) 40px;
}

.iconWrapper{
	text-align: center;
	margin-top: 5px;
}

.day{
	padding: 5px;
	border-bottom: 2px solid #e0e0e0;
	text-align: center;
	font-size: 10px;
	color: gray;
}

.day:hover{
	cursor: pointer;
}

.selectedDay{
	color: #2962ff;
	border-bottom: 3px solid #2962ff;
}

.selectWeekWrapper{
	display: flex;
	margin-right: 20px;
	height:35px;
	width: 320px;
}

.v-text-field .v-input__control .v-input__slot {
	min-height: auto !important; 
}

.filterWrapper{
	padding: 10px;
	display: flex;
	width: 100%;
	align-items: center;
}

.v-select {
	width: 50%;
}

table{
	border-collapse: collapse;
}

.tableHeader {
	background: #FBC02D;
	border: 1.5px solid #e0e0e0;
	overflow: hidden;
}

.tableRow{
	display: grid;
	grid-template-columns: 200px repeat(7, 120px) 82px;
}

@media screen and (min-width: 1180px) {
	.tableRow{
		grid-template-columns: 200px repeat(7, 120px) 82px;;
	}
}

@media only screen and (max-width: 959px) { 
	.tableRow {
		grid-template-columns: repeat(2, 1fr);
	}
}

.userFullNameTitle{
	font-size: 14px;
}

.tableCell {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border: 1.5px solid #e0e0e0;
	overflow: hidden;
}

.absentLongLabelWrapper{
	width: 100%;
	text-align: center;
	margin-top: 20%;
	font-size: 14px;
}

.absentShortLabelWrapper{
	font-size: 14px;
}

.v-list-item{
	min-height: 20px !important;
}

.tableFooter{
	font-size: 14px;
}

.selectWrapper{
	padding-top: 10px;
	width: 100px;
}
</style>
